export const getSelectedUserModule = (moduleList) => {
  return {
    type: "USER_MODULES",
    payload: moduleList
  };
};
export const getSelectedFarmModule = (farmModules) => {
  return {
    type: "FARM_MODULES",
    payload: farmModules
  };
};
export const getSelectedCorporate = (corporate) => {
  return {
    type: "SELECTED_CORPORATE",
    payload: corporate
  };
};
export const getFarmUser = (farmUser) => {
  return {
    type: "FARM_USER",
    payload: farmUser
  };
};
export const corporateChange = (corporateId) => {
  return {
    type: "CORPORATE_CHANGE",
    payload: corporateId
  };
};
export const corporateList = (corporateList) => {
  return {
    type: "CORPORATE_LIST",
    payload: corporateList
  };
};
export const getFilterCorporateList = (filterCorporateList) => {
  return {
    type: "FILTER_CORPORATE_LIST",
    payload: filterCorporateList
  };
};
export const getSelectedBuyer = (buyer) => {
  return {
    type: "SELECTED_BUYER",
    payload: buyer
  };
};