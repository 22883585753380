import React, {useEffect, useState} from "react";
import {hasPermission,} from "../../../utils/Authentication";
import {useDispatch, useSelector} from "react-redux";
import {ACTUATOR_TYPE, IRRIGATION_MODE, IRRIGATION_STATUS} from "../../../utils/Enum";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import {resetConfirmationDialog, toggleConfirmationDialog, toggleLoader} from "../../../shared/actions/setting";
import {toast} from "react-toastify";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import {getMQTTStatusPlot, getPlotIrrigationStatus} from "../../../actions/plot";
import {useTranslation} from "react-i18next";
import {getUserId, getUsername, isSuper} from "../../../shared/utils/SharedAuthentication";
import {dateFormat, isEmpty} from "../../../shared/utils/utils";
import {onValue, ref} from "firebase/database";
import {database} from "../../../utils/firebaseConfig";
import {useParams} from "react-router-dom";
import {getFarmSource} from "../../../actions/farm";
import Switch from "react-switch";

const PlotIrrigationManual = (props) => {
  const {farmId} = useParams();
  const [plotManualIrrigation, setPlotManualIrrigation] = useState([]);
  const [sourceKit, setSourceKit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [wateringTime, setWateringTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [dateRange, setDataRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date()),
  });
  const [isTable, setTable] = useState(false);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  const plotMqttStatus = useSelector(state => {
    return state.plotList.plotMqtt;
  });

  const plotIrrigationStatus = useSelector(state => {
    return state.plotList.plotIrrigationStatus
  });

  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog;
  });

  useEffect(() => {
    getIrrigationStatus()
  }, [props.plotId]);

  useEffect(() => {
    const calculateDuration = () => {
      if (plotIrrigationStatus.irrigationStartedAt) {
        const currentTime = new Date();
        const irrigationStartDate = new Date(currentTime.toDateString() + ' ' + plotIrrigationStatus.irrigationStartedAt);
        const durationInMinutes = Math.round((currentTime - irrigationStartDate) / (1000 * 60));
        setDuration(durationInMinutes);
      }
    };

    // Call the function initially
    calculateDuration();

    // Set up an interval to recalculate every minute
    const intervalId = setInterval(() => {
      calculateDuration();
    }, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [plotIrrigationStatus]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/irrigation/` + props.plotId + `/watering`)
      .then(function (response) {
        if (response.data.content) {
          setWateringTime(response.data.content)
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });

  }, [props.plotId]);

  useEffect(() => {
    if (!props.plotId) {
      return
    }

    const query = ref(database, 'irrigation/' + farmId + '/' + props.plotId);
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      console.log('FB:ref=> irrigation/' + farmId + '/' + props.plotId + " data=>", data);  //dont delete this log
      if (snapshot.exists()) {
        data = {...plotIrrigationStatus, ...data}
        dispatch(getPlotIrrigationStatus(data));
      }
    });
  }, [props.plotId]);

  useEffect(() => {
    if (!farmSource.id) {
      return
    }

    const query = ref(database, 'valves/' + farmId + '/' + farmSource.id);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      console.log('FB:ref=> valves/' + farmId + '/' + farmSource.id + " data=>", data);  //dont delete this log
      if (snapshot.exists()) {
        dispatch(getFarmSource(data));
      }
    });
  }, [farmSource.id]);

  useEffect(() => {
    if (!isLoading || props.irrigation.mode !== IRRIGATION_MODE.MANUAL) {
      return;
    }

    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation/status/' + status)
      .then(function (response) {
        let history = {};
        history.user = {};
        history.user.username = getUsername();
        history.actionAt = new Date().toISOString().substring(0, 10) + " " + new Date().toString().substring(16, 24);

        if (status === IRRIGATION_STATUS.ON) {
          props.irrigation.status = IRRIGATION_STATUS.ON_REQUESTED;
          history.action = "MANUAL_MODE_IRRIGATION_ON";
        } else if (status === IRRIGATION_STATUS.OFF) {
          props.irrigation.status = IRRIGATION_STATUS.OFF_REQUESTED;
          history.action = "MANUAL_MODE_IRRIGATION_OFF";
        }
        let date = dateRange;
        setDataRange(null);
        setDataRange(date);
        setPlotManualIrrigation(values => [history, ...values]);
        getIrrigationStatus();
        toast.success("Requested to " + status + " irrigation for this plot");
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
      });
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading || props.irrigation.mode === IRRIGATION_MODE.MANUAL) {
      return;
    }

    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation/force-update-status/' + status)
      .then(function (response) {
        toast.success("Requested to " + status + " irrigation for this plot");
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        setIsLoading(false);
      });
  }, [isLoading]);

  function getIrrigationStatus() {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + `/irrigation/status`)
      .then(function (response) {
        if (response.data.content) {
          dispatch(getPlotIrrigationStatus(response.data.content));
        }
      })
      .catch(function (error) {
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }


  useEffect(() => {
    if (!dateRange) {
      return;
    }
    dispatch(toggleLoader(true));
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date(Date.now() - 604800000));
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/plot/' + props.plotId + '/irrigation/manual/history' + '?from=' + fromDate + '&to=' + toDate + '&order=' + 'DESC')
      .then(function (response) {
        setPlotManualIrrigation(response.data.content);
        setIsLoading(false);
        // setDataRange(null);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [dateRange, props.plotId]);


  useEffect(() => {
    if (isEmpty(farmSource)) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/farm/' + farmId + '/source/' + farmSource.code + '/kit')
      .then(function (response) {
        setSourceKit(response.data.content);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      }).finally(() => {
      dispatch(toggleLoader(false));
    })
  }, [farmSource,props.plotId]);

  useEffect(() => {
    if (!plotMqttStatus) {
      return
    }
    if (plotMqttStatus.plotId === props.plotId) {
      props.irrigation.status = plotMqttStatus.status;
      dispatch(getMQTTStatusPlot(null));
    }
  }, [plotMqttStatus]);

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(null);
    setDataRange(dates);

  }

  function manualIrrigationConfirmation() {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      type: 'request',
      ...(!props.visibleWidget && {
      confirmationHeading: ((!plotIrrigationStatus.status || plotIrrigationStatus.status === IRRIGATION_STATUS.OFF) ? t("confirmation_msg.ARE_YOU_SURE_TO_TURN_ON") : t("confirmation_msg.ARE_YOU_SURE_TO_TURN_OFF"))
      ,
      confirmationDescription: ((!plotIrrigationStatus.status || plotIrrigationStatus.status === IRRIGATION_STATUS.OFF) ? t("confirmation_msg.THE_ACTION_WILL_TURN_ON_IRRIGATION") : t("confirmation_msg.THE_ACTION_WILL_TURN_OFF_IRRIGATION"))}),
      ...(props.visibleWidget && {
      confirmationHeading: `Irrigation type is in ${props.irrigation.mode} mode`,
      confirmationDescription: ((!plotIrrigationStatus.status || plotIrrigationStatus.status === IRRIGATION_STATUS.OFF) ? t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THE_STATUS_TURN_ON") : t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_CHANGE_THE_STATUS_TURN_OFF"))})
    }));
  }

  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    if ((confirmationDialog.onSuccess) && (plotIrrigationStatus.status === IRRIGATION_STATUS.OFF || !plotIrrigationStatus.status)) {
      setStatus('ON');
      setIsLoading(true);
      dispatch(resetConfirmationDialog());
    } else if ((confirmationDialog.onSuccess) && [IRRIGATION_STATUS.ON, IRRIGATION_STATUS.PENDING, IRRIGATION_STATUS.ON_REQUESTED].includes(plotIrrigationStatus.status)) {
      setStatus('OFF');
      setIsLoading(true);
      dispatch(resetConfirmationDialog());
    }

  }, [confirmationDialog]);

  useEffect(() => {
    if (!isTable) {
      return
    }
    let elem = document.getElementById("scroll");
    if (elem) {
      elem.scrollIntoView();
    }
  }, [isTable]);

  const irrigationSwitchChange = () => {
    if ([IRRIGATION_STATUS.ON, IRRIGATION_STATUS.PENDING, IRRIGATION_STATUS.ON_REQUESTED].includes(plotIrrigationStatus.status)) {
      manualIrrigationConfirmation(); // Trigger turn off
    } else {
      manualIrrigationConfirmation(); // Trigger turn on
    }
  };  

  return (
    <div>
      <div className="text-center">
        {farmSource && farmSource.type === ACTUATOR_TYPE.MOTOR &&
          <div className={"ir-manual-ind"}>
            <div className={"ir-manual-ind-c left " + (farmSource.flow ? "success" : "error")}>
              <FeatherIcon className={"ir-manual-icon"} icon="zap"/>
              <div className={"ir-manual-title"}>{t("title.POWER")}</div>
            </div>
            <div className={"ir-manual-ind-c right " + (plotIrrigationStatus.status === "ON" ? "success" : "error")}>
              <FeatherIcon className={"ir-manual-icon"} icon="git-commit"/>
              <div className={"ir-manual-title"}>{t("title.MOTOR")}</div>
            </div>
            <div
              className={"ir-manual-ind-line"}> {plotIrrigationStatus && plotIrrigationStatus.description
              ? plotIrrigationStatus.description
              : "-"}
              <br/>
              {isSuper() && plotIrrigationStatus && plotIrrigationStatus.status === IRRIGATION_STATUS.ON_REQUESTED
                ? (wateringTime ? ` ${wateringTime}` : '')
                : ''}
            </div>
            <div className={"ir-manual-ind-line-text"}><FeatherIcon className={"ir-manual-icon-alert"}
                                                                    icon="alert-circle"/></div>
          </div>
        }
        {farmSource && farmSource.type === ACTUATOR_TYPE.TANK &&
          <div className={"ir-manual-ind"}>
            <div
              className={"ir-manual-ind-line-tank"}>{plotIrrigationStatus.description ? plotIrrigationStatus.description : "-"}</div>
            <div className={"ir-manual-ind-line-text"}><FeatherIcon className={"ir-manual-icon-alert"}
                                                                    icon="alert-circle"/></div>
          </div>
        }
        {plotIrrigationStatus.status === IRRIGATION_STATUS.ON && duration > 0 &&
          <div className="turn-off-text">{`Watering (${duration} mins).`}</div>
        }
        {plotIrrigationStatus.status === IRRIGATION_STATUS.ON && duration === 0 &&
          <div className="turn-off-text">{`Watering (0 mins).`}</div>
        }
        {hasPermission("WRITE") && !props.visibleWidget && !props.hideButton ?
          [IRRIGATION_STATUS.ON, IRRIGATION_STATUS.PENDING, IRRIGATION_STATUS.ON_REQUESTED].includes(plotIrrigationStatus.status) ?
            <button
              className={"btn btn-sa-danger w-140p m-b-7 m-t-25"}
              onClick={() => {
                manualIrrigationConfirmation();
              }}>{t("button.TURN_OFF")}
            </button>
            :
            <button className={"btn btn-sa-success w-140p m-b-7 m-t-25"}
                    onClick={() => {
                      manualIrrigationConfirmation();
                    }}>{t("button.TURN_ON")}
            </button> : " "
        }

        {hasPermission("WRITE") && props.visibleWidget && !props.hideButton ?
          <Switch
          checked={[IRRIGATION_STATUS.ON, IRRIGATION_STATUS.PENDING, IRRIGATION_STATUS.ON_REQUESTED].includes(plotIrrigationStatus.status)}
          onChange={irrigationSwitchChange} onColor="#22C674" offColor="#D3D3D3" onHandleColor="#fff" handleDiameter={20} uncheckedIcon={false}
          checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={25}
          width={46} className="react-switch ml-auto" id="material-switch"
        />
        : " "}
      </div>

      {!props.hideButton && !props.visibleWidget &&
        <>
          <div className={"table-container-toggle " + (isTable ? "" : "table-toggle-hide")}>
            <hr className="table-toggle-line"/>
            <div className="table-toggle-heading">{t("title.MANUAL_MODE_CHANGE_HISTORY")}</div>
            <div onClick={() => setTable(!isTable)} className="table-dropdown-icon"><i
              className={"fa " + (isTable ? "fa-angle-up" : "fa-angle-down")}/></div>
          </div>

          <div id={"scroll"} className={(isTable ? "" : "hide-table")}>
            <div className={"plot-report-filter-box"}>
              <div className={"plot-report-filter m-t-26"}>
                <DateRangePicker disabledDate={date => date > new Date()} disabled={false} onChange={(e) => onCheck(e)}
                                 oneTap={false} onOk={(e) => onCheck(e)}
                                 showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                                 ranges={[{
                                   label: 'Today',
                                   value: [new Date(), new Date()]
                                 }, {
                                   label: 'Yesterday',
                                   value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                                 }, {
                                   label: 'Last 7 days',
                                   value: [dateFns.subDays(new Date(), 6), new Date()]
                                 }, {
                                   label: 'Last 30 days',
                                   value: [dateFns.subDays(new Date(), 30), new Date()]
                                 }]}
                />
              </div>
            </div>
            <div id="scrollableDiv" className={"sa-table-container mh-420 xindicate-calc-height"}>
              <table className="table table-borderless sa-table-width">
                <thead>
                <tr>
                  <th className={"sa-table-head-sticky"}> {t("title.USER")}</th>
                  <th className={"sa-table-head-sticky"}> {t("title.ACTION")}</th>
                  <th className={"sa-table-head-sticky"}> {t("title.DATE_AND_TIME")}</th>
                </tr>
                </thead>
                <tbody>
                {plotManualIrrigation.length > 0 && (plotManualIrrigation.map((irrigation, index) =>
                  <tr key={index}>
                    <td className={'sa-table-data'}>{irrigation.user ? irrigation.user.username : 'Unknown'}</td>
                    <td className={'sa-table-data'}>
                      <div
                        className="table-status-active w-90">{irrigation.action === "MANUAL_MODE_IRRIGATION_OFF" ? "OFF" : "ON"}</div>
                    </td>
                    <td className={'sa-table-data'}>{irrigation.actionAt}</td>
                  </tr>
                ))}
                </tbody>
              </table>
              {plotManualIrrigation.length === 0 && (
                <div className={"empty-results"}>
                  <FeatherIcon icon="info"/>
                  <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_MANUAL_IRRIGATION_HISTORIES")}</div>
                </div>)
              }
            </div>
          </div>
        </>}
    </div>
  );

};

export default PlotIrrigationManual;
